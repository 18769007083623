import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import getOnlyPublished from '../utils/functions';
import {
  SiteSiteMetadata,
  MarkdownRemarkConnection,
} from '../../types/graphql-types';
import { PageContext } from '../context';

const H1 = styled.h1`
  line-height: 1.2;
  margin: 0 0 1.6rem;
  padding: 0 0.8rem;
`;

const AllTagsWrapper = styled.div`
  margin: 0 0 1.6rem;
  padding: 0 0.8rem;
`;

interface TagsPageContext extends PageContext {
  tag: string;
}

interface Props {
  pageContext: TagsPageContext;
  data: {
    site: {
      siteMetadata: SiteSiteMetadata;
    };
    allMarkdownRemark: MarkdownRemarkConnection;
  };
  location: Location;
}

const TagsTemplate: React.FC<Props> = ({
  pageContext,
  data,
  location,
}: Props) => {
  const { tag } = pageContext;
  const { edges: allPosts, totalCount } = data.allMarkdownRemark;

  const siteTitle = data.site.siteMetadata.title;
  const posts =
    process.env.NODE_ENV === 'production'
      ? getOnlyPublished(allPosts)
      : allPosts;

  const tagHeader = `
    ${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "#${tag}"
  `;

  return (
    <Layout location={location} title={siteTitle}>
      <H1>{tagHeader}</H1>
      {posts.length > 0 && (
        <ul>
          {posts.map(({ node: post }) => {
            const { slug } = post.fields;
            const { title } = post.frontmatter;
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            );
          })}
        </ul>
      )}
      <AllTagsWrapper>
        <Link to="/tags/">All tags</Link>
      </AllTagsWrapper>
    </Layout>
  );
};

export default TagsTemplate;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            published
          }
        }
      }
    }
  }
`;
